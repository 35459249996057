import React from 'react'

function LogoutPage() {
    return (
        <div>
            Logout page
        </div>
    )
}

export default LogoutPage
