export const walletData = [
  {
    name: "Fiat Wallet",
    image: "",
    balance: "N3,847,895",
    transactions: [
      {
        _id: "tdg87wdgh7udh32bd...",
        user: "gerald@123",
        activity: "deposited",
        type: "debit",
        amount: "1000",
        createdAt: "12/03/20",
      },
      {
        _id: "tdg87wdgh7udh32bd...",
        user: "gerald@123",
        activity: "deposited",
        type: "debit",
        amount: "1000",
        createdAt: "12/03/20",
      },
      {
        _id: "tdg87wdgh7udh32bd...",
        user: "avanish7667",
        activity: "deposited",
        type: "debit",
        amount: "1000",
        createdAt: "12/03/20",
      },
      {
        _id: "tdg87wdgh7udh32bd...",
        user: "avanish7667",
        activity: "deposited",
        type: "debit",
        amount: "1000",
        createdAt: "12/03/20",
      },
      {
        _id: "tdg87wdgh7udh32bd...",
        user: "avanish7667",
        activity: "deposited",
        type: "debit",
        amount: "1000",
        createdAt: "12/03/20",
      },
      {
        _id: "tdg87wdgh7udh32bd...",
        user: "adedamola456",
        activity: "deposited",
        type: "debit",
        amount: "1000",
        createdAt: "12/03/20",
      },
    ],
    color: "#7FBA56;",
  },
  {
    name: "Cliq Token",
    image: "",
    balance: "N3,847,895",
    transactions: [
      {
        _id: "tdg87wdgh7udh32bd...",
        user: "avanish123",
        activity: "deposited",
        type: "credit",
        amount: "1000",
        createdAt: "12/03/20",
      },
      {
        _id: "tdg87wdgh7udh32bd...",
        user: "avanish123",
        activity: "deposited",
        type: "debit",
        amount: "1000",
        createdAt: "12/03/20",
      },
      {
        _id: "tdg87wdgh7udh32bd...",
        user: "avanish123",
        activity: "deposited",
        type: "credit",
        amount: "1000",
        createdAt: "12/03/20",
      },
      {
        _id: "tdg87wdgh7udh32bd...",
        user: "adedamola456",
        activity: "deposited",
        type: "debit",
        amount: "1000",
        createdAt: "12/03/20",
      },
      {
        _id: "tdg87wdgh7udh32bd...",
        user: "adedamola456",
        activity: "deposited",
        type: "debit",
        amount: "1000",
        createdAt: "12/03/20",
      },
      {
        _id: "tdg87wdgh7udh32bd...",
        user: "adedamola456",
        activity: "deposited",
        type: "credit",
        amount: "1000",
        createdAt: "12/03/20",
      },
      {
        _id: "tdg87wdgh7udh32bd...",
        user: "gerald421",
        activity: "deposited",
        type: "debit",
        amount: "1000",
        createdAt: "12/03/20",
      },
      {
        _id: "tdg87wdgh7udh32bd...",
        user: "gerald421",
        activity: "deposited",
        type: "credit",
        amount: "1000",
        createdAt: "12/03/20",
      },
      {
        _id: "tdg87wdgh7udh32bd...",
        activity: "deposited",
        type: "debit",
        user: "adedamola456",
        amount: "1000",
        createdAt: "12/03/20",
      },
      {
        _id: "tdg87wdgh7udh32bd...",
        activity: "deposited",
        type: "debit",
        user: "adedamola456",
        amount: "1000",
        createdAt: "12/03/20",
      },
    ],
    color: "red",
  },
  // {
  //   name: "SUSD",
  //   image: "",
  //   balance: "N3,847,895",
  //   transactions: [
  //     {
  //       activity: "deposited",
  //       type: "credit",
  //       amount: "1000",
  //       createdAt: "12/03/20",
  //     },
  //     {
  //       activity: "deposited",
  //       type: "credit",
  //       amount: "1000",
  //       createdAt: "12/03/20",
  //     },
  //     {
  //       activity: "deposited",
  //       type: "credit",
  //       amount: "1000",
  //       createdAt: "12/03/20",
  //     },
  //     {
  //       activity: "deposited",
  //       type: "credit",
  //       amount: "1000",
  //       createdAt: "12/03/20",
  //     },
  //     {
  //       activity: "deposited",
  //       type: "credit",
  //       amount: "1000",
  //       createdAt: "12/03/20",
  //     },
  //   ],
  //   color: "yellow",
  // },
];
