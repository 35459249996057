import React from 'react'

function HelpPage() {
    return (
        <div>
            Help Page
        </div>
    )
}

export default HelpPage
