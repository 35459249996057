import ProductImage1 from "assets/productimg1.png";
import ProductImage2 from "assets/productimg2.png";
import ProductImage3 from "assets/productimg3.png";
import jane from "assets/mem/user.svg";
import dotun from "assets/mem/dotun.jpeg";
import jonah from "assets/mem/glasses.png";
import joe from "assets/mem/sca.png";
import LadyPic from "assets/svg/lady.svg";
import lady from "assets/svg/lady.svg";
import guy from "assets/svg/userIcon.svg";

export const publishedCards = [
  {
    categoryId: "1",
    brandName: "Aerospace",
    contactEmail: "",
    contactPhoneNumber: "",
    businessTags: [],
    businessCategories: [],
    username: "Gerald",
    lng: "",
    price: "N 3400",
    lat: "",
    description:
      "For regular goods for good exchange, the exchange area shows a list of goods that have been indicated for trading and then any user who has a new good to be traded for another comes to the exchange and indicates their good.",
    productName: "aero parts",
    productImages: [ProductImage1, ProductImage2, ProductImage3],
    profileUrl: "",
  },
  {
    categoryId: "2",
    brandName: "Automotive",
    contactEmail: "",
    contactPhoneNumber: "",
    businessTags: [],
    businessCategories: [],
    username: "Gerald",
    lng: "",
    price: "N 3400",
    lat: "",
    description:
      "For regular goods for good exchange, the exchange area shows a list of goods that have been indicated for trading and then any user who has a new good to be traded for another comes to the exchange and indicates their good.",
    productName: "Machinary",
    productImages: [ProductImage3, ProductImage2, ProductImage1],
    profileUrl: "",
  },
  {
    categoryId: "3",
    brandName: "Real Estate",
    contactEmail: "",
    contactPhoneNumber: "",
    businessTags: [],
    businessCategories: [],
    username: "Gerald",
    lng: "",
    price: "N 3400",
    lat: "",
    description:
      "For regular goods for good exchange, the exchange area shows a list of goods that have been indicated for trading and then any user who has a new good to be traded for another comes to the exchange and indicates their good.",
    productName: "Plot",
    productImages: [ProductImage2, ProductImage1, ProductImage3],
    profileUrl: "",
  },
  {
    categoryId: "4",
    brandName: "Rail",
    contactEmail: "",
    contactPhoneNumber: "",
    businessTags: [],
    businessCategories: [],
    username: "Gerald",
    lng: "",
    price: "CT 65000",
    lat: "",
    description:
      "For regular goods for good exchange, the exchange area shows a list of goods that have been indicated for trading and then any user who has a new good to be traded for another comes to the exchange and indicates their good.",
    productName: "Engine",
    productImages: [ProductImage3, ProductImage1, ProductImage2],
    profileUrl: "",
  },
  {
    categoryId: "6",
    brandName: "House",
    contactEmail: "",
    contactPhoneNumber: "",
    businessTags: [],
    businessCategories: [],
    username: "Avanish",
    lng: "",
    price: "CT 65000",
    lat: "",
    description:
      "For regular goods for good exchange, the exchange area shows a list of goods that have been indicated for trading and then any user who has a new good to be traded for another comes to the exchange and indicates their good.",
    productName: "Cement",
    productImages: [ProductImage2, ProductImage3, ProductImage2],
    profileUrl: "",
  },
  {
    categoryId: "7",
    brandName: "Laptop",
    contactEmail: "",
    contactPhoneNumber: "",
    businessTags: [],
    businessCategories: [],
    username: "Avanish",
    lng: "",
    price: "CT 12300",
    lat: "",
    description:
      "For regular goods for good exchange, the exchange area shows a list of goods that have been indicated for trading and then any user who has a new good to be traded for another comes to the exchange and indicates their good.",
    productName: "Lenevo",
    productImages: [ProductImage3, ProductImage1, ProductImage3],
    profileUrl: "",
  },
  {
    categoryId: "8",
    brandName: "Bean Bag",
    contactEmail: "",
    contactPhoneNumber: "",
    businessTags: [],
    businessCategories: [],
    username: "Avanish",
    lng: "",
    price: "CT 12300",
    lat: "",
    description:
      "For regular goods for good exchange, the exchange area shows a list of goods that have been indicated for trading and then any user who has a new good to be traded for another comes to the exchange and indicates their good.",
    productName: "Bag",
    productImages: [ProductImage2, ProductImage3, ProductImage2],
    profileUrl: "",
  },
  {
    categoryId: "9",
    brandName: "Aerospace",
    contactEmail: "",
    contactPhoneNumber: "",
    businessTags: [],
    businessCategories: [],
    username: "Gerald",
    lng: "",
    price: "N 3400",
    lat: "",
    description:
      "For regular goods for good exchange, the exchange area shows a list of goods that have been indicated for trading and then any user who has a new good to be traded for another comes to the exchange and indicates their good.",
    productName: "Aeroplanes",
    productImages: [ProductImage1, ProductImage2, ProductImage3],
    profileUrl: "",
  },
  {
    categoryId: "10",
    brandName: "Automotive",
    contactEmail: "",
    contactPhoneNumber: "",
    businessTags: [],
    businessCategories: [],
    username: "Gerald",
    lng: "",
    price: "N 3400",
    lat: "",
    description:
      "For regular goods for good exchange, the exchange area shows a list of goods that have been indicated for trading and then any user who has a new good to be traded for another comes to the exchange and indicates their good.",
    productName: "Tyres",
    productImages: [ProductImage3, ProductImage2, ProductImage1],
    profileUrl: "",
  },
  {
    categoryId: "11",
    brandName: "Real Estate",
    contactEmail: "",
    contactPhoneNumber: "",
    businessTags: [],
    businessCategories: [],
    username: "Gerald",
    lng: "",
    price: "N 3400",
    lat: "",
    description:
      "For regular goods for good exchange, the exchange area shows a list of goods that have been indicated for trading and then any user who has a new good to be traded for another comes to the exchange and indicates their good.",
    productName: "Building",
    productImages: [ProductImage2, ProductImage1, ProductImage3],
    profileUrl: "",
  },
];

export const draftCards = [
  {
    categoryId: "12",
    brandName: "Aerospace",
    contactEmail: "",
    contactPhoneNumber: "",
    businessTags: [],
    businessCategories: [],
    username: "Gerald",
    lng: "",
    price: "N 3400",
    lat: "",
    description:
      "For regular goods for good exchange, the exchange area shows a list of goods that have been indicated for trading and then any user who has a new good to be traded for another comes to the exchange and indicates their good.",
    productName: "Service Product",
    productImages: [ProductImage1, ProductImage2, ProductImage3],
    profileUrl: "",
  },
  {
    categoryId: "13",
    brandName: "Automotive",
    contactEmail: "",
    contactPhoneNumber: "",
    businessTags: [],
    businessCategories: [],
    username: "Gerald",
    lng: "",
    price: "N 3400",
    lat: "",
    description:
      "For regular goods for good exchange, the exchange area shows a list of goods that have been indicated for trading and then any user who has a new good to be traded for another comes to the exchange and indicates their good.",
    productName: "Service Product",
    productImages: [ProductImage3, ProductImage2, ProductImage1],
    profileUrl: "",
  },
  {
    categoryId: "14",
    brandName: "Real Estate",
    contactEmail: "",
    contactPhoneNumber: "",
    businessTags: [],
    businessCategories: [],
    username: "Gerald",
    lng: "",
    price: "N 3400",
    lat: "",
    description:
      "For regular goods for good exchange, the exchange area shows a list of goods that have been indicated for trading and then any user who has a new good to be traded for another comes to the exchange and indicates their good.",
    productName: "Service Product",
    productImages: [ProductImage2, ProductImage1, ProductImage3],
    profileUrl: "",
  },
  {
    categoryId: "15",
    brandName: "Rail",
    contactEmail: "",
    contactPhoneNumber: "",
    businessTags: [],
    businessCategories: [],
    username: "Gerald",
    lng: "",
    price: "CT 65000",
    lat: "",
    description:
      "For regular goods for good exchange, the exchange area shows a list of goods that have been indicated for trading and then any user who has a new good to be traded for another comes to the exchange and indicates their good.",
    productName: "Service Product",
    productImages: [ProductImage3, ProductImage1, ProductImage2],
    profileUrl: "",
  },
];

export const messagedCards = [
  {
    id: 1,
    user: "Jane Doe",
    userImage: jane,
    title: "New shoes delivered",
    message: "Nice service on Spottr. xoxo",
    time: "1 hour",
    isOnline: false,
  },
  {
    id: 2,
    user: "Dotun Sobambo",
    userImage: dotun,
    title: "Black soap looks good",
    message: "Top notch service on Spottr. Love you guys",
    time: "2 seconds",
    isOnline: true,
  },
  {
    id: 3,
    user: "Jonah Whale",
    userImage: jonah,
    title: "Whale delivered successfully",
    message: "I'm currently in the whale's belly",
    time: "35 minutes",
    isOnline: false,
  },
  {
    id: 4,
    user: "Joseph Obomano",
    userImage: joe,
    title: "New shoes delivered",
    message: "Nice service on Spottr. xoxo",
    time: "3 hours",
    isOnline: true,
  },
  {
    id: 5,
    user: "Jonah Whale",
    userImage: jonah,
    title: "Whale delivered successfully",
    message: "I'm currently in the whale's belly",
    time: "35 minutes",
    isOnline: false,
  },
  {
    id: 6,
    user: "Joseph Obomano",
    userImage: joe,
    title: "New shoes delivered",
    message: "Nice service on Spottr. xoxo",
    time: "3 hours",
    isOnline: true,
  },
];
export const trendingCards = [
  {
    location: "Lagos, Nigeria",
    categoryId: "16",
    brandName: "10 Baskets of Tomatoes",
    contactEmail: "",
    contactPhoneNumber: "",
    businessTags: [],
    businessCategories: [],
    username: "@Gerald",
    name: "Gerald",
    price: "N 4800,00",
    lng: "82.9739144",
    lat: "25.3176452",
    description:
      "For regular goods for good exchange, the exchange area shows a list of goods that have been indicated for trading and then any user who has a new good to be traded for another comes to the exchange and indicates their good.",
    productName: "Coker & Sons ltd",
    productImages: [ProductImage1, ProductImage2, ProductImage3],
    profileUrl: LadyPic,
  },
  {
    location: "Lagos, Nigeria",
    categoryId: "17",
    brandName: "2 Baskets of Onion",
    contactEmail: "",
    contactPhoneNumber: "",
    businessTags: [],
    businessCategories: [],
    name: "Gerald",
    username: "@Servicesltd",
    lng: "82.9739144",
    lat: "25.3176452",
    price: "N 4800,00",
    description:
      "For regular goods for good exchange, the exchange area shows a list of goods that have been indicated for trading and then any user who has a new good to be traded for another comes to the exchange and indicates their good.",
    productName: "Coker & Sons ltd",
    productImages: [ProductImage1, ProductImage2, ProductImage3],
    profileUrl: LadyPic,
  },
];

export const referalData = [
  {
    image: lady,
    name: "Amaka Mbah",
    uname: "saucekode",
  },
  {
    image: guy,
    name: "Lola Minijojo",
    uname: "lojo",
  },
  {
    image: lady,
    name: "Amaka Mbah",
    uname: "saucekode",
  },
  {
    image: guy,
    name: "Lola Minijojo",
    uname: "lojo",
  },
  {
    image: guy,
    name: "Lola Minijojo",
    uname: "lojo",
  },
  {
    image: lady,
    name: "Amaka Mbah",
    uname: "saucekode",
  },
];
